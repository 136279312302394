@import url('https://fonts.cdnfonts.com/css/sf-mono');

.display{
    background-color: transparent;
    height: 60px;
    font-size: 40px;
    width: 274px;
    font-family: 'SF Mono', sans-serif;
    border: none;
    text-align: right;
    color: white;
    resize: none;
    white-space: nowrap;
    overflow-y: hidden;
}

*:focus {
    outline: none;
}

@media screen and (max-width: 480px){
    .display{
        width: 90%;
        height: 100%;
        font-size: 35px;
    }

    textarea{
        padding-left: 10px;
    }
}

